var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "DefaultCharacter",
        {
          attrs: {
            isLoadingData: _vm.isLoadingData,
            character: _vm.character,
            isColored: "",
            isLevel: "",
          },
        },
        [
          _c(
            "template",
            { slot: "header" },
            [_c("market-place-header", { attrs: { urls: _vm.getPathUrls } })],
            1
          ),
          _vm.GET_SHOP_CHARACTER
            ? _c("template", { slot: "actions" }, [
                _c(
                  "div",
                  { staticClass: "shop-actions" },
                  [
                    _c("btn", {
                      staticClass: "avatar-btn",
                      attrs: {
                        type: "icon-gradient",
                        text: `BUY ${_vm.toLocaleFormat(
                          _vm.GET_SHOP_CHARACTER.price
                        )} MF`,
                        hoverText: `BUY ${
                          _vm.toLocaleFormat(
                            _vm.GET_SHOP_CHARACTER.nft.mf_attributes
                              .sale_usd_price
                          ) || 100
                        } $`,
                        disabled: _vm.isMyCharacter,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onBuyNftClick(_vm.GET_SHOP_CHARACTER)
                        },
                      },
                    }),
                    _vm.isMyCharacter
                      ? _c("btn", {
                          staticClass: "avatar-btn",
                          attrs: {
                            type: "icon-gradient",
                            text: "CANCEL ORDER",
                            subText: `on sale for ${_vm.toLocaleFormat(
                              _vm.GET_SHOP_CHARACTER.price
                            )} MF`,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onCancelListingClick(
                                _vm.GET_SHOP_CHARACTER.nft.mf_attributes
                                  .token_id
                              )
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
      _c("approve-popup", {
        ref: "approvePopup",
        attrs: {
          "action-btn-text": "BUY",
          successMsg:
            "You have approved a sufficient number of MF tokens. Now you can buy the character.",
        },
        on: {
          "action-btn-click": function ($event) {
            return _vm.buyCharacter(
              _vm.GET_SHOP_CHARACTER.nft.mf_attributes.token_id
            )
          },
        },
      }),
      _c("transaction-success-popup", { ref: "transactionSuccessPopup" }),
      _vm.isProcessing
        ? _c("preloader", { attrs: { translucent: "", black: "" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }