// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".avatar-btn {\n  color: black;\n  width: 210px;\n  margin-top: 3em;\n  border: none;\n}\n.shop-actions {\n  gap: 1em;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n@media screen and (max-width: 640px) {\n.shop-actions {\n    flex-direction: column;\n}\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
