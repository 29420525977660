<template>
    <div>
        <DefaultCharacter
            :isLoadingData="isLoadingData"
            :character="character"
            isColored
            isLevel
        >
            <template slot="header">
                <market-place-header :urls="getPathUrls" />
            </template>

            <template v-if="GET_SHOP_CHARACTER" slot="actions">
                <div class="shop-actions">
                    <btn
                        @click="onBuyNftClick(GET_SHOP_CHARACTER)"
                        class="avatar-btn"
                        type="icon-gradient"
                        :text="`BUY ${ toLocaleFormat(GET_SHOP_CHARACTER.price) } MF`"
                        :hoverText="`BUY ${toLocaleFormat(GET_SHOP_CHARACTER.nft.mf_attributes.sale_usd_price) || 100} $`"
                        :disabled="isMyCharacter"
                    />
                    <btn
                        v-if="isMyCharacter"
                        @click="onCancelListingClick(GET_SHOP_CHARACTER.nft.mf_attributes.token_id)"
                        class="avatar-btn"
                        type="icon-gradient"
                        :text="'CANCEL ORDER'"
                        :subText="`on sale for ${ toLocaleFormat(GET_SHOP_CHARACTER.price) } MF`"
                    />
                </div>
            </template>
        </DefaultCharacter>

        <approve-popup
            ref="approvePopup"
            action-btn-text="BUY"
            successMsg="You have approved a sufficient number of MF tokens. Now you can buy the character."
            @action-btn-click="buyCharacter(GET_SHOP_CHARACTER.nft.mf_attributes.token_id)"
        />
        <transaction-success-popup ref="transactionSuccessPopup"/>
        <preloader translucent black v-if="isProcessing" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DefaultCharacter from '@/components/common/default-character.vue'
import MarketPlaceHeader from '@/components/market-place/header.vue'
import marketplaceMixin from '@/mixins/marketplaceMixin';
import {isStringsEquals} from "@/utils/helpers";

export default {
    name: 'ShopSingle',
    mixins: [marketplaceMixin],
    props: {
        person: { type: [String, Number], default: '' },
    },
    components: {
        DefaultCharacter,
        MarketPlaceHeader,
    },
    data() {
        return {
            isLoadingData: true,
        }
    },
    computed: {
        ...mapGetters(['GET_IS_MOBILE', 'GET_SHOP_CHARACTER', 'account']),
        ...mapGetters('auth', ['user']),

        character() {
            if (!this.GET_SHOP_CHARACTER) {
                return {}
            }

            return {
                ...this.GET_SHOP_CHARACTER.nft.mf_attributes,
                description: this.GET_SHOP_CHARACTER.nft.description,
                name: this.GET_SHOP_CHARACTER.nft.name,
            }
        },
        getPathUrls() {
            return [
                {
                    label: 'Marketplace',
                    name: 'Market',
                },
                {
                    label: this.character.name,
                },
            ]
        },
        isMyCharacter() {
            return isStringsEquals(this.GET_SHOP_CHARACTER.seller, this.account);
        },
    },
    async mounted() {
        await this.REQUEST_SHOP_CHARACTER(this.person);

        if (!this.GET_SHOP_CHARACTER) {
            this.$router.push({name: 'Market'}).catch(()=>{});
        }
        this.isLoadingData = false;
    },
    methods: {
        ...mapActions(['REQUEST_SHOP_CHARACTER']),
    },
}
</script>

<style lang="scss">
.avatar-btn {
    color: black;
    width: 210px;
    margin-top: 3em;
    border: none;
}
.shop-actions {
    gap: 1em;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $bp_mb) {
        flex-direction: column;
    }
}
</style>
